<template>
  <div id="app">
    <EndRoll />
  </div>
</template>

<script>
import EndRoll from "./components/EndRoll.vue";
export default {
  name: "app",
  components: {
    EndRoll
  }
};
</script>

<style>
:root {
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none;    /* Firefox 対応 */
  margin:0;
}
:root::-webkit-scrollbar {
  display: none; /* Chrome, Safari 対応 */
}
body {
  margin:0;
}
</style>