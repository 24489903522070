<template>
  <div>
    <section>
      <video
        id="bgvideo"
        src="~@/assets/bvideo.mp4"
        type="video/mp4"
        webkit-playsinline
        playsinline
        muted
      ></video>
      <audio
        id="bgm"
        src="~@/assets/bgm.mp3"
        type="audio/mpeg"
        loop
      ></audio>
      <div id="icons" class="icondiv">
        <img class="star4" id="iconstay" src="~@/assets/logo_outline.png" />
        <img
          class="sticon"
          id="iconb"
          src="~@/assets/logo.png"
          v-on:click="startdown()"
          style="display: none;"
        />
        <img class="sticon" id="icona" src="~@/assets/logo_color.png" style="display: none;" />
      </div>
      <div v-if="displayData&&gmData&&show>=1&&getready">
        <div v-if="show==2">
          <div class="titlediv">
            <img class="sttitle" src="~@/assets/titleHeader.png" />
          </div>
          <div v-for="(display, index) in displayData" v-bind:key="index">
            <div class="mondai">
              <div class="monme">
                {{display.num}}: {{display.title}}
                <br />
                現地: {{display.locate}}
                <br />
              </div>
              <div v-if="display.saku" class="names">
                作問:
                <div>
                  <span
                    v-for="(sakumon, sakuIndex) in display.saku"
                    v-bind:key="display.num + sakuIndex + 'saku' + sakumon"
                  >
                  {{sakumon}}<br>
                  </span>
                </div>
                <br />現地班:
                <div>
                  <span
                    v-for="(tape, tapeIndex) in display.tape"
                    v-bind:key="display.num + tapeIndex + 'tape' + tape"
                  >
                  {{tape}}<br>
                  </span>
                </div>
                <br />セブン班:
                <div>
                  <span
                    v-for="(sevenHan, sebanIndex) in display.seban"
                    v-bind:key="display.num + sebanIndex + 'seban' + sevenHan"
                  >
                  {{sevenHan}}<br>
                  </span>
                </div>
                <br />甜菜:
                <div>
                  <span
                    v-for="(tensaisan, tensaiIndex) in display.tensai"
                    v-bind:key="display.num + tensaiIndex + 'tensai' + tensaisan"
                  >
                  {{tensaisan}}<br>
                  </span>
                </div>
                <br />勇者:
                  <div>
                    <span
                      v-for="(yushasama, yushaIndex) in display.yusha"
                      v-bind:key="display.num + yushaIndex + 'yusha' + yushasama"
                    >
                    {{yushasama}}<br>
                    </span>
                  </div>
              </div>
            </div>
          </div>
          <div v-for="members in gmData" v-bind:key="members.type">
            <p class="hd">{{members.type}}</p>
            <div class="gmname" v-for="gm in members.GM" v-bind:key="members.type + gm">{{gm}}</div>
          </div>
          <br />
          <br />
          <transition>
            <div id="owari" class="findiv">
              <img
                class="fin"
                src="~@/assets/fin.png"
              />
            </div>
          </transition>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import gmDataJson from "@/assets/gmdata.json";
export default {
  name: "EndRoll",
  data() {
    return {
      displayData: null,
      gmData: null,
      scTime: null,
      show: -1,
      scSpeed: 5,
      dly: 3000,
      scrollY: 0,
      getready: null
    };
  },
  created: function() {
    this.gmData = gmDataJson;
  },
  async mounted() {
    await axios.get('https://script.google.com/macros/s/AKfycbzmHHjKBwyPNmi1_2fyalIhnX_DJ1FodSpFAaVfRALHjDtA817b1lPmmcx_DrL4tGTw/exec')
      .then(response => {
        this.displayData = response.data.data
        this.gmData = response.data.gm
      })
    window.addEventListener("scroll", this.handleScroll);
    let media = document.getElementById("bgvideo");
    var id = setInterval(
      function() {
        console.log(media.networkState);
        if (media.networkState == 1) {
          this.getready = true;
          this.tglid();
          console.log(this.getready);
          clearInterval(id); //idをclearIntervalで指定している
        }
      }.bind(this),
      100
    );
  },
  methods: {
    tgl() {
      this.show = 1;
    },
    tglid() {
      let icons = document.getElementById("iconstay");
      icons.style.display = "none";
      let iconb = document.getElementById("iconb");
      iconb.style.display = "block";
    },
    startdown() {
      console.log("down");
      // 再生処理
      let myvideo = document.getElementById("bgvideo");
      let mybgm = document.getElementById("bgm");
      mybgm.volume=0.05;
      myvideo.play();
      mybgm.play();
      // アイコン差し替え
      let iconb = document.getElementById("iconb");
      iconb.style.display = "none";
      let icona = document.getElementById("icona");
      icona.style.display = "block";
      this.show = 2;
      const step = 2.5;
      const interval = 25; // 0.025秒ごとに移動
      let now = 0;
      setInterval(
        function() {
          scrollBy(0, step); // スクロール位置を移動
          now++;
          if (now == 151) {
            now = 0;
          }
        }.bind(this),
        interval
      );
    },
    handleScroll() {
      this.scrollY = window.scrollY;
      let media = document.getElementById("bgvideo");
      media.playbackRate = 1;
      media.loop = true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://script.google.com/macros/s/AKfycbx2ARsCjHbDpAv_W677L-eT3XFWMFbwmz4GGd-igDniJsSrSn1YtHjyevUfzkt9KblN/exec");
.fontall {
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
}
.tback {
  /* width: 80vw; */
  font-size: 3rem;
  text-align: center;
}
.monme {
  /* width: 80vw; */
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: #75b39d;
  font-size: 1.2rem;
  text-align: center;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: bolder;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
}
.names {
  /* width: 80vw; */
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: #f05783;
  font-size: 1.5rem;
  text-align: center;
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
}
.w3w {
  font-size: 0.9rem;
}
.gmname {
  /* width: 80vw; */
  color: #d4f2e8;
  font-size: 2rem;
  text-align: center;
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-shadow: 1px 1px 0 #75b39d, -1px -1px 0 #75b39d, -1px 1px 0 #75b39d,
    1px -1px 0 #75b39d, 0px 1px 0 #75b39d, 0-1px 0 #75b39d, -1px 0 0 #75b39d, 1px 0 0 #75b39d;
}
.gmname1 {
  /* width: 80vw; */
  font-size: 2rem;
  color: #d4f2e8;
  text-align: center;
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-shadow: 1px 1px 0 #75b39d, -1px -1px 0 #75b39d, -1px 1px 0 #75b39d,
    1px -1px 0 #75b39d, 0px 1px 0 #75b39d, 0-1px 0 #75b39d, -1px 0 0 #75b39d, 1px 0 0 #75b39d;
}
.hd {
  /* width: 80vw; */
  margin-top: 3rem;
  color: #ffd5eb;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-shadow: 1px 1px 0 #f05783, -1px -1px 0 #f05783, -1px 1px 0 #f05783,
    1px -1px 0 #f05783, 0px 1px 0 #f05783, 0-1px 0 #f05783, -1px 0 0 #f05783, 1px 0 0 #f05783;
}
.flt {
  /* width: 80vw; */
  color: black;
  font-size: 1rem;
  text-align: center;
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
}
.icondiv {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9b3b4;
  margin: 0;
}
.titlediv, .findiv {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sttitle {
  display: flex;
  justify-content: center;
  width: 90vw;
}
.fin {
  width: 80vw;
  margin-bottom:3rem;
}
.mac {
  width: 50vw;
}
.sticon {
  width: 30vw;
}
video {
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  -moz-background-size: 100vw 100vh;
  background-size: 100vw 100vh;
  z-index: -1;
}
.header-title {
  position: relative; /*必ず必要*/
  z-index: 2; /*必ず必要*/
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}
section {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}
.mondai {
  margin-bottom: 3rem;
  margin-top: 3rem;
}
.star4 {
  width: 30vw;
  animation: r4 60s steps(60) infinite;
}
@keyframes r4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>